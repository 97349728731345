import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransitionGroup as Transition } from 'react-transition-group'

import useBlockScroll from '../../hooks/useBlockScroll'
import { useTheme } from '../../styled'
import { timeToMs } from '../../utils/functions'
import ModalCurtain from './ModalCurtain'

interface ModalBaseProps {
  isOpen: boolean
  setIsOpen: (val: boolean) => void
  enableClose?: boolean
  backdrop?: 'light' | 'dark' | 'dark-transparent'
}

const ModalBackdropToColorMapping = {
  light: 'seastone',
  dark: 'black',
  'dark-transparent': 'darkTransparentOverlayBg',
}

const ModalBase: React.FC<ModalBaseProps> = ({
  isOpen,
  setIsOpen,
  children,
  enableClose = true,
  backdrop = 'light',
}) => {
  const [target, setTarget] = useState<HTMLElement | void>()
  const theme = useTheme()

  const duration = timeToMs(theme.durations.default)

  useBlockScroll(isOpen)
  useEffect(() => setTarget(document.body), [])

  if (!target) {
    return null
  }

  return ReactDOM.createPortal(
    <Transition
      transitionName="modal"
      transitionEnterTimeout={duration}
      transitionLeaveTimeout={duration}
      style={{
        zIndex: theme.zIndices.modal,
        position: 'relative',
      }}
    >
      {isOpen && (
        <ModalCurtain
          background={ModalBackdropToColorMapping[backdrop]}
          onClick={() => {
            if (enableClose) {
              setIsOpen(false)
            }
          }}
        />
      )}
      {isOpen && children}
    </Transition>,
    target
  )
}

export default ModalBase
