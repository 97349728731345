import { Button, ButtonProps, Flex } from '@chakra-ui/core'
import React from 'react'

import Icon from '../Icon/Icon'
import { Anchor } from './types'

export type DrawerCloseProps = Omit<ButtonProps, 'children'> & {
  anchor?: Anchor
  removeSelf: () => void
}

const DrawerClose: React.FC<DrawerCloseProps> = ({
  anchor = 'left',
  removeSelf,
  ...buttonProps
}) => {
  return (
    <Button
      variant="blank"
      borderRadius="full"
      onClick={() => removeSelf()}
      width="2rem"
      height="2rem"
      {...buttonProps}
    >
      <Flex
        borderRadius="full"
        border="1px"
        borderColor="seaMist"
        width="1.5rem"
        height="1.5rem"
        align="center"
        justify="center"
      >
        <Icon fontSize=".75rem" name={`chevron-${anchor}`} />
      </Flex>
    </Button>
  )
}

export default DrawerClose
